.chat-header {
    background-color: #282c34;
    padding: 1rem;
    color: white;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    height: 3%;
    /* position: relative; */
  }

  

.submit-button {
    flex: 10%;
    border-radius: 5px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.switches-container {
    width: auto;
    /* This controls the width of the switches container */
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    /* Align checkboxes to the right */
}

.switch-label {
    position: relative;
    display: flex;
    align-items: center; /* Centers the switch and label text */
    justify-content: flex-start;
    margin: 10px;
  }

/* .switch-label {
    display: flex;
    align-items: center;
    /* Ensure checkbox and label are aligned */
    /* justify-content: flex-start; */
/* }  */

.switch-label span {
    font-size: 14px;
    margin-right: 10px;
    /* Space between text and checkbox */
    color: white;
}

input[type="checkbox"] {
    transform: scale(1.2);
    /* Slightly enlarge the checkbox if needed */
}

/* Tooltip styles */
.switch-label[data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    color: #333333;
    padding: 5px 10px;
    border-radius: 4px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
    font-size: 12px;
    z-index: 1000;
  }
  
  /* Small triangle at the bottom of the tooltip */
  .switch-label[data-tooltip]:hover::before {
    content: '';
    position: absolute;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent;
  }