* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow: hidden;
  background-color: #282c34;
}

.chat-app {
  flex: 1; /* Takes the remaining height available after the ChatHeader */
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevents overflow of chat messages */
  height: 100%;
}

.out-container {
  display: flex;
  flex-direction: column;
  height: 100vh; 
}

.title-container {
  width: 80%; /* This controls the width of the title area */
  text-align: center;
}

.chat-container {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f5f5f5;
}

.chat-message {
  margin: 0.5rem 0;
  padding: 0.75rem;
  border-radius: 10px;
  max-width: 70%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.user-message {
  background-color: #d1e7ff;
  align-self: flex-end;
}

.bot-message {
  background-color: #e1e1e1;
  align-self: flex-start;
}

.mainPage {
  padding: 29pt;
}

.centerText {
  text-align: center;
}

.drop .btn {
  text-align: left;
  width: 100%;
}

.drop .dropdown-menu {
  width: 100%;
}

.label-info-new {
  color: #fff;
  border-radius: 11px;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 0.6px;
  background-color: #0088A9;
  padding: 0 8px;
  word-break: keep-all;
}
