/* src/components/SQLStatement.css */

.code-container {
  margin-top: 10px;
  background-color: black; /* Background color for the SQL code */
  border-radius: 5px;
  padding: 10px;
  color: white; /* Text color for the SQL code */
  position: relative; /* Set position relative for absolute positioning of the button */
  height: 600px; /* Set a fixed height (adjust as needed) */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  width: 80%;
}


pre {
  margin: 0; /* Remove default margins */
  overflow-x: auto; /* Enable horizontal scrolling for long SQL queries */
}

.copy-button {
  background-color: #007bff; /* Primary button color */
  color: white; /* Button text color */
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 2%; /* Space above the button */
  position: absolute; /* Position it relative to the code container */
  right: 10px; /* Align to the right */
  width: 10%;
}


.execute-button {
  background-color: #007bff; /* Primary button color */
  color: white; /* Button text color */
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 6%; /* Space above the button */
  position: absolute; 
  right: 10px; /* Align to the right */
  width: 10%;
}

.copy-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
