.chat-input-container {
    display: flex;          /* Keep elements in a row */
    align-items: flex-end;  /* Align items to the bottom */
    padding: 1rem;
    background-color: #f5f5f5;
}

.textarea-container {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    border-radius: 25px;   /* Circular border */
    background-color: white; /* Match background color */
    border: 1px solid #ddd; /* Border to define the container */
    padding: 5px; /* Add some padding around */
}

.chat-input {
    border: none;            /* Remove border */
    outline: none;          /* Remove outline */
    padding: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent; /* Make background transparent */
    border-radius: 25px;   /* Match the container's circular border */
    resize: none;           /* Disable resizing */
}


.submit-button {
    position: absolute;
    border: none;
    
    color: white;              /* White icon */
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;       /* Makes the button circular */
    transition: background-color 0.3s; /* Smooth background color transition */
}

.submit-button:hover {
    background-color: #45a049; /* Darker green on hover */
}

.file-select {
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    width: 20%; /* Fixed width */
    height: 40px; /* Fixed height */
    margin-left: 10px; /* Space between select and textarea */
}
